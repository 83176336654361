import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ element }) => {
    const { isAuthenticated } = useAuth();
    return !isAuthenticated ? <Navigate to="/" />:element;

};

export default ProtectedRoute;
