import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AdminPage from "./component/AdminPage";
import Login from "./component/Login";
import ProtectedRoute from "./component/ProtectedRoute";
import { AuthProvider } from "./component/AuthContext";
import PublicRoute from "./component/PublicRoute";

function App() {

  return (
    <Router>
      <AuthProvider>
      <Routes>
      <Route path="/" element={<PublicRoute element={<Login />} />} />
        <Route path="/admin" element={<ProtectedRoute element={<AdminPage />} />} />
      </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
