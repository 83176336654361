
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import axios from "axios";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";
import { useAuth } from "./AuthContext";
import { useNavigate } from 'react-router-dom';
// Define the database URL constant
// const databaseURL = "https://bubbly-cascade-419919-default-rtdb.firebaseio.com";
const databaseURL = "https://payment-c9754-default-rtdb.firebaseio.com/"

function AdminPage() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [first, setFirst] = useState(0);
  const [upiId, setUpiId] = useState("");
  const [newUpiId, setNewUpiId] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const [newAccountDetails, setNewAccountDetails] = useState({
    holderName: "",
    accountNumber: "",
    ifscCode: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("upi");

  const handleToggle = (method) => {
    setSelectedMethod(method);
  };
  const { logout } = useAuth();
  useEffect(() => {
    // Fetch UPI ID and account details on component mount
    axios
      .get(`${databaseURL}/UserData.json`)
      .then((response) => {
        const data = response.data ? Object.values(response.data) : [];
        setProducts(data);
        if (data.length > 0 && data[0].UpiId) {
          setUpiId(data[0].UpiId);
          setNewUpiId(data[0].UpiId); 
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });

    axios
      .get(`${databaseURL}/Account/AccountDetails.json`)
      .then((response) => {
        const data = response.data;
        if (data) {
          setAccountDetails(data);
          setNewAccountDetails({
            holderName: data.holderName || "",
            accountNumber: data.accountNumber || "",
            ifscCode: data.ifscCode || "",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching account details:", error);
      });

    axios
      .get(`${databaseURL}/Account/UpiID.json`)
      .then((response) => {
        const data = response.data;
        if (data && data.UpiId) {
          setUpiId(data.UpiId);
          setNewUpiId(data.UpiId); // Set newUpiId to the fetched UPI ID
        }
      })
      .catch((error) => {
        console.error("Error fetching UPI ID:", error);
      });
  }, []);

  const addOrUpdateUpiId = () => {
    if (newUpiId.trim()) {
      const upiData = { UpiId: newUpiId };
      axios
        .put(`${databaseURL}/Account/UpiID.json`, upiData)
        .then((response) => {
          console.log("UPI ID updated successfully:", response.data);
          setUpiId(newUpiId);
          toast.success("UPI ID updated successfully");
        })
        .catch((error) => {
          console.error("Error updating UPI ID:", error);
          toast.error("Failed to update UPI ID");
        });
    }
  };

  const addOrUpdateAccountDetails = () => {
    const { holderName, accountNumber, ifscCode } = newAccountDetails;
    if (holderName.trim() && accountNumber.trim() && ifscCode.trim()) {
      axios
        .put(`${databaseURL}/Account/AccountDetails.json`, newAccountDetails)
        .then((response) => {
          console.log("Account details updated successfully:", response.data);
          toast.success("Account details updated successfully");
          setAccountDetails(newAccountDetails); // Update local state
        })
        .catch((error) => {
          console.error("Error updating account details:", error);
          toast.error("Failed to update account details");
        });
    }
  };

  const openModal = (url) => {
    setSelectedImage(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={rowData.File}
        alt="File"
        style={{ width: "50px", height: "50px", cursor: "pointer" }}
        onClick={() => openModal(rowData.File)}
      />
    );
  };

  const downloadImage = () => {
    if (selectedImage) {
      const link = document.createElement("a");
      link.href = selectedImage;
      link.download = "downloaded-image.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Handle input changes for account details
  const handleAccountInputChange = (e, field) => {
    setNewAccountDetails({
      ...newAccountDetails,
      [field]: e.target.value,
    });
  };
  const handleLogout = () => {
    logout();

    navigate('/');
};
  return (
    <div className="container-fluid min-vh-100 pt-2">
      <ToastContainer />
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <div className="card">
            <div className="d-flex align-items-center gap-3 payment-method p-3 pb-0">
              <button
                type="button"
                onClick={() => handleToggle("upi")}
                className={selectedMethod === "upi" ? "active" : ""}
              >
                UPI ID
              </button>
              <button
                type="button"
                onClick={() => handleToggle("creditCard")}
                className={selectedMethod === "creditCard" ? "active" : ""}
              >
                Account Transfer
              </button>
              <button
                type="button"
                onClick={() => handleLogout()}
                className={'float-left'}
                style={{background:"red",color:"white"}}
              >
                Logout
              </button>
            </div>
            {selectedMethod === "upi" && (
              <div className="p-inputgroup w-100 d-flex" style={{ padding: "1rem" }}>
                <InputText
                  placeholder={upiId ? "Edit UPI ID" : "Add New UPI ID"}
                  value={newUpiId}
                  onChange={(e) => setNewUpiId(e.target.value)}
                />
              </div>
            )}
            {selectedMethod === "creditCard" && (
              <div className="d-flex gap-3 flex-column flex-lg-row p-3">
                <input
                  type="text"
                  placeholder="Holder Name"
                  className="form-control"
                  value={newAccountDetails.holderName}
                  onChange={(e) => handleAccountInputChange(e, "holderName")}
                />
                <input
                  type="text"
                  placeholder="Account Number"
                  className="form-control"
                  value={newAccountDetails.accountNumber}
                  onChange={(e) => handleAccountInputChange(e, "accountNumber")}
                />
                <input
                  type="text"
                  placeholder="IFSC"
                  className="form-control"
                  value={newAccountDetails.ifscCode}
                  onChange={(e) => handleAccountInputChange(e, "ifscCode")}
                />
              </div>
            )}
            <div className="px-3 pb-3">
              {selectedMethod === "upi" && (
                <Button
                  label={upiId ? "Update" : "Add"}
                  icon="pi pi-plus"
                  onClick={addOrUpdateUpiId}
                  className="p-ml-2"
                />
              )}
              {selectedMethod === "creditCard" && (
                <Button
                  label={accountDetails ? "Update Account" : "Add Account"}
                  icon="pi pi-plus"
                  onClick={addOrUpdateAccountDetails}
                  className="p-ml-2"
                />
              )}
            </div>
            <div className="table-responsive">
              <DataTable
                scrollable
                scrollHeight="422px"
                virtualScrollerOptions={{ itemSize: 46 }}
                value={products}
                loading={loading}
                first={first}
                onPage={(e) => setFirst(e.first)}
                totalRecords={products.length}
                tableStyle={{ minWidth: "100%" }}
              >
                <Column
                  field="Transactionid"
                  header="Transaction ID"
                  sortable
                  style={{ width: "30%" }}
                ></Column>
                <Column
                  field="File"
                  header="File"
                  body={imageBodyTemplate}
                  style={{ width: "30%" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={{
          content: {
            width: "100%",
            maxWidth: "80vw",
            height: "60%",
            maxHeight: "80vh",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <div>
          <div className="text-right">
            <button onClick={downloadImage} className="btn btn-primary mb-3">
              Download
            </button>
          </div>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected File"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </div>
        <button onClick={closeModal} className="btn btn-primary">
          Close Modal
        </button>
      </Modal>
    </div>
  );
}

export default AdminPage;
